.tagList {
  display: block;
  .listType {
    background: #F6F8FB;
    padding: 16px 0;
    margin-top: 16px;
    border-radius: 4px;
    :global(.but-title) {
      margin-bottom: 4px;
    }
  }
  .title {
    margin-top: 12px;
    padding-bottom: 6px;
    font-size: 14px;
    color: #00000059;
  }
  .listBox {
    max-height: 260px;
    overflow: auto;
    .tagItem {
      box-sizing: border-box;
      width: 100%;
      padding: 9px 12px;
      .action {
        display: none;
        float: right;
      }
      &:hover {
        .action {
          display: inline-block;
        }
      }
    }
  }
}
.footer {
  padding: 24px 0 0;
}
