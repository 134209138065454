.tumorStageSelect {
  position: relative;
  .content {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 100%;
    min-width: 260px;
    padding: 0px 0 8px 0;
    background-color: #fff;
    border: 1px solid rgba(5, 5, 5, 0.13);
    border-radius: 2px;
    box-shadow: 0 4px 12px 0 #0000001f;
    transform: translateY(calc(100% + 4px));
    .column {
      text-align: center;
      border-right: 1px solid #f0f0f0;
      .title {
        padding: 7px 12px;
        text-align: left;
        background-color: #f4f8fe;
      }
      .body {
        height: 180px;
        padding: 0 2px;
        overflow-x: hidden;
        overflow-y: auto;
        &::-webkit-scrollbar {
          width: 6px;
          height: 6px;
          background-color: rgba(0, 0, 0, 0);
        }
        &::-webkit-scrollbar-thumb {
          background-color: #ccc;
          border-radius: 10px;
        }
        .selectItem {
          padding: 7px 12px;
          text-align: left;
          cursor: pointer;

          &:hover {
            background-color: rgba(0, 0, 0, 0.04);
          }
          &.selected {
            font-weight: 500;
            background-color: #f0f8ff;
          }
        }
      }
    }
    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 8px 0 8px;
      border-top: 1px solid #f0f0f0;
    }
  }
}
