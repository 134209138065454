.tags {
  .tagItem {
    padding: 3px 8px;
    background: #E6F1FC;
    border: 1px solid #A3D0FD;
    border-radius: 12px;
    color: #4288FF;
  }
  .addBtn {
    color: #0b214540;
    border-color: #0b214540;
  }
}
