.imageOcr-parsing {
  position: relative;
  flex: 0 0 358px;
  max-height: 130vh;
  padding: 16px;
  overflow-y: auto;
  background: #ffffff;
  border: 1px solid #dcdfe6;
  border-radius: 4px;

  .closeBtn {
    position: absolute;
    top: 16px;
    right: 16px;
    z-index: 99;
  }

  .parsing-title {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-bottom: 2px;
  }

  .label {
    color: #303030;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
  }

  .image-ocr {
    flex: 0 0 100%;
    // height: 514px;
    margin-bottom: 16px;
    overflow: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .report-text {
    margin-bottom: 8px;
    padding: 9px 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
    border-radius: 4px;
  }

  .parsing-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    padding: 8px;
    background: #f6f8fb;
    border-radius: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .container {
    color: #0b2145;
    font-size: 12px;
    line-height: 18px;
  }
}
