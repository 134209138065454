@import '@/style/var.less';

.sortList {
  width: 100%;
  .item {
    .header {
      padding: 13px 16px 13px 40px;
      background: #F6F8FB;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      color: #303030;
      .action {
        float: right;
      }
      :global {
        .handle {
          position: absolute;
          left: 0px;
          top: 0px;
          padding: 14px 11px;
          cursor: pointer;
          .anticon-holder {
            font-size: 20px;
            color: @primary-color;
          }
        }
      }
    }
    .body {
      width: 100%;
      padding: 16px 0;
    }
  }
}
