@import '@/style/var.less';

.timeline {
  background-color: #f5f6fb;
}

.timeline-cases {
  position: relative;
}

.timeline-tool {
  position: absolute;
  top: 16px;
  left: 16px;
  z-index: 9;
  box-sizing: border-box;
  width: 175px;
  padding: 0 16px 24px 16px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 #00000026;

  .tool-title {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    color: #303030;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;

    &::before {
      position: absolute;
      top: 50%;
      left: -16px;
      width: 4px;
      height: 14px;
      background: #4288ff;
      border-radius: 0 4px 4px 0;
      transform: translateY(-50%);
      opacity: 1;
      content: '';
    }
  }
}

.scale-zoom {
  position: absolute;
  top: 56px;
  right: 26px;
  z-index: 9;
}

.timeline-canvas {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: auto;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;

  // &::-webkit-scrollbar {
  //   width: 0;
  //   height: 0;
  // }
}

.timeline-canvas-height {
  height: calc(100vh - 86px);
}

.timeline-container {
  // width: auto;
  // height: auto;
  width: fit-content;

  .container {
    display: flex;
    box-sizing: border-box;
    width: max-content;
    // min-width: 100vw;
    // min-height: 100vh;
    // margin: 200px 0;
    transform-origin: center center;
    transition: transform 0.3s ease;
  }
}

.no-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-bgimage {
  background-color: #f5f6fb;
  background-image: url('~@/assets/images/lecture/timeline_bg.png');
}
