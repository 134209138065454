@import '@/style/mixin.less';

.field-item {
  .label-wrapper {
    min-width: 80px;
  }

  .field-label {
    font-weight: 500;
    font-size: 14px;
    .addFontSize(14px);
    color: #4288FF;
    letter-spacing: 0;
    flex-shrink: 0;
    display: inline-block;
    line-height: 16px;
    background: #e6f1fc;
    border: 1px solid #a3d0fd;
    border-radius: 4px;
    padding: 4px 8px;
  }

  .deep-label {
    background: #4288FF;
    border: 1px solid #a3d0fd;
    color: #ffffff;
  }

  .field-desc {
    flex: 1;
    font-size: 14px;
    .addFontSize(14px);
    color: #000000bf;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: 10px;
    word-break: break-all;
  }

  .desc-button {
    font-weight: 500;
    font-size: 14px;
    color: #4288FF;
    letter-spacing: 0;
    line-height: 24px;
    cursor: pointer;
  }
}

.field-text {
  display: flex;
  align-items: start;
}

.field-button {
  display: flex;
  align-items: center;
}

.field-image {
  .label-wrapper {
    margin-bottom: 16px;
  }
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;

  .images {
    width: 100px;
    height: 100px;
    background: rgba(139, 175, 227, 0.35);
    border-radius: 4px;
    margin-right: 12px;
    margin-bottom: 12px;

    :global(.ant-image) {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      width: 100px;
      height: 100px;
      overflow: hidden;
    }
  }

  .video-label {
    object-fit: cover;
    cursor: pointer;
  }

  .no-images {
    font-size: 14px;
    .addFontSize(14px);
    color: rgba(0, 0, 0, 0.75);
  }
}

.field-border {
  margin-bottom: 16px;
  padding-bottom: 16px;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    border-top: 1px dashed #97979759;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;

    &::after {
      display: none;
    }
  }
}