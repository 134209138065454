@import "@/style/var";
.box{   
  height: 100%; 
  padding-top: 20%;
  .cont{ 
    display: flex; 
    justify-content: center; 
    .imgs{
      width: 280px;
      height: 190px;
    }
    .title{
      font-size: 48px;
      color: rgba(0,0,0,0.65);
    }
    .con{
      font-size: 14px;
      color: rgba(0,0,0,0.65);
    }
  }
}
