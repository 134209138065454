@import '@/style/mixin.less';

.speech-card {
  background: #ffffff;
  box-shadow: 1px 1px 4px 0 #0000001a;
  margin-bottom: 10px;

  .card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding-right: 16px;

    :global {
      .ant-space-item {
        display: flex;
      }
    }

    .title {
      font-weight: 500;
      font-size: 16px;
      .addFontSize(16px);
      padding: 16px 16px 16px 12px;
      color: #303030;
      flex-shrink: 0;
    }

    .sub-title {
      font-size: 12px;
      .addFontSize(12px);
    }

    &::before {
      content: '';
      display: block;
      width: 4px;
      height: 14px;
      background: #4288FF;
      border-radius: 0 2px 2px 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    .close {
      cursor: pointer;
    }
  }
}