.score {
  padding: 16px 0;
  background-color: #fff;
  margin-bottom: 12px;
  border-radius: 4px;
  :global {
    .but-title {
      margin-bottom: 0;
    }
  }
}

.patientDetailTabs {
  background-color: #fff;
  margin-top: 12px;
  border-radius: 4px;
  padding: 16px 0;
  :global {
    .but-title {
      margin-bottom: 4px;
    }
    .record-form {
      .ant-form-item {
        border-bottom: 1px #eee dashed;
        margin-bottom: 0;
        padding: 16px 0;
      }
    }
    .tabBox {
      width: 100%;
      display: flex;
      min-height: 360px;
    }
  }
}

.btn {
  margin-left: 16px;
  background-image: linear-gradient(227deg, #83DDF8 0%, #4C83EE 100%);
  border-color: #fff;
  box-shadow: none;
  :global {
    .iconfont {
      font-size: 14px;
    }
  }
}
