.speech-draft {
  height: 100vh;
  background-color: #f5f8fb;

  .speech-container {
    display: flex;
    height: calc(100% - 72px);
    overflow: hidden;
  }
}

.speech-form-item {
  margin-bottom: 0;
}

.flex-form {
  flex: 1;
}