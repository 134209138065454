.empty {
  display: inline-block;
  margin: 0 auto;
  .emptyIcon {
    width: 250px;
    height: 250px;
    background-image: url('../../assets/images/list/empty.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    &.search {
      background-image: url('../../assets/images/list/search-empty.svg');
    }
  }
}
