.imdt-chart {

  .chart-title {
    font-weight: 600;
    font-size: 16px;
    color: #0163A3;
    padding: 16px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 14px;
      background: #4288FF;
      border-radius: 0 4px 4px 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .title-border {

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #3779AE;
      box-shadow: 0 6px 20px 0 #015596cc;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.chart-bgi {
  background-image: url('~@/assets/images/statistic/bg_icon.png');
  background-position: 0 0;
}