@import '@/style/mixin.less';

.step {
  .step-header {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 0;

    .step-icon {
      width: 12px;
      height: 12px;
      display: inline-block;
      background: #4288FF;
      border-radius: 50%;
      border: 3px solid #ffffffb3;
    }

    .step-title {
      font-weight: 500;
      font-size: 14px;
      .addFontSize(14px);
      color: #303030;
      margin-left: 16px;
    }
  }

  .step-container {
    padding: 16px 0 24px 16px;
    position: relative;

    &::before {
      content: '';
      display: block;
      height: calc(100% + 5px);
      width: 1px;
      background-color: #e2e7ed;
      position: absolute;
      left: 8px;
      top: 0;
      z-index: 0;
    }
  }

  &:last-child {
    .step-container {
      padding-bottom: 0;
    }
  }
}