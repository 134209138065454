@import '@/style/var.less';
@import '../axisVar.less';

.horizontal {
  position: relative;
  flex-shrink: 0;
  gap: 20px;
  padding-right: 20px;
  padding-left: 70px;

  &::before {
    position: absolute;
    top: 16px;
    left: 44px;
    z-index: 1;
    z-index: 0;
    display: block;
    width: 100%;
    // height: 2px;
    // background-color: @line-color;
    border-top: 2px solid @line-color;
    content: '';
  }

  &:last-child {
    &::after {
      position: absolute;
      top: 9px;
      right: -54px;
      width: 0;
      height: 0;
      border-color: transparent transparent transparent @line-color; /* 上下左颜色为透明，右边为红色 */
      border-style: solid;
      border-width: 8px; /* 控制三角形大小 */
      content: '';
    }
  }

  .month {
    position: relative;
    z-index: 9;
    flex-shrink: 0;
    align-self: first baseline;
    width: @month-button-width;
    // padding: 0 12px;
    height: 32px;
    margin-left: -75px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    background: @primary-color;
    border-radius: 50px;
  }

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: @axis-gap @axis-gap;
    padding-top: 80px;

    &::before {
      position: absolute;
      top: 0;
      left: -26px;
      z-index: 1;
      display: block;
      // width: 2px;
      border-left: 2px solid @line-color;
      height: calc(100% - var(--lastElementHeight) / 2);
      // background-color: @line-color;
      content: '';
    }
  }

  .month-line {
    &::before {
      position: absolute;
      top: -5px;
      right: -50px;
      z-index: 1;
      display: block;
      // width: 2px;
      height: 50px;
      // background-color: @line-color;
      border-left: 2px solid @line-color;
      content: '';
    }
  }

  .close {
    position: absolute;
    top: 28px;
    left: -34px;
    z-index: 9;
    display: flex;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .axis-item {
    position: relative;
    width: fit-content;
    min-width: @axis-min-width;
    max-width: 534px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;

    &::before {
      position: absolute;
      top: 50%;
      left: -9px;
      display: block;
      width: 5px;
      height: 5px;
      background-color: #fff;
      border: 2px solid @line-color;
      border-radius: 50%;
      transform: translateY(-50%);
      content: '';
    }

    &::after {
      position: absolute;
      top: 50%;
      left: -22px;
      display: block;
      width: 15px;
      border-top: 2px solid @line-color;
      // height: 2px;
      // background-color: @line-color;
      transform: translateY(-50%);
      content: '';
    }

    .day {
      position: absolute;
      top: 50%;
      left: -100px;
      z-index: 10;
      width: @axis-day-width;
      transform: translateY(-50%);
    }

    .timeline {
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        left: -30px;
        z-index: 11;
        display: block;
        width: 6px;
        height: 6px;
        background-color: @blue-color;
        border: 1px solid #c4daff;
        border-radius: 50%;
        transform: translateY(-50%);
        content: '';
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 12px;
        color: #fff;
        font-size: 14px;
      }

      .axis {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 12px;
      }

      .detail {
        position: relative;
        padding-left: 12px;
        color: #2c4060;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        word-wrap: break-word;
        word-break: break-all;

        &::before {
          position: absolute;
          top: 10px;
          left: 0;
          display: block;
          width: 6px;
          height: 6px;
          background-color: @blue-color;
          border-radius: 50%;
          content: '';
        }
      }

      .preview {
        width: fit-content;
        padding-left: 12px;
      }
    }
  }

  .red {
    border-color: @red-color;

    .timeline {
      .header {
        background-color: @red-color;
      }

      .detail {
        &::before {
          background-color: @red-color;
        }
      }
    }
  }

  .blue {
    border-color: @blue-color;

    .timeline {
      .header {
        background-color: @blue-color;
      }

      .detail {
        &::before {
          background-color: @blue-color;
        }
      }
    }
  }

  .green {
    border-color: @green-color;

    .timeline {
      .header {
        background-color: @green-color;
      }

      .detail {
        &::before {
          background-color: @green-color;
        }
      }
    }
  }

  .purple {
    border-color: @purple-color;

    .timeline {
      .header {
        background-color: @purple-color;
      }

      .detail {
        &::before {
          background-color: @purple-color;
        }
      }
    }
  }
}
