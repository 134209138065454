.body {
  .desc {
    margin-bottom: 12px;
  }
  .images {
    .item {
      width: 100px;
      height: 100px;
      background: #8bafe359;
      border-radius: 4px;
      display: flex;
      :global {
        .ant-image {
          margin: auto;
          max-height: 100%;
          max-width: 100%;
        }
      }
    }
  }
}
