@import '@/style/var.less';
@import '../axisVar.less';

.vertical {
  position: relative;
  display: flex;
  flex-shrink: 0;
  flex-wrap: nowrap;
  gap: 20px;
  padding-top: @axis-padding-top;
  padding-bottom: 20px;

  &::before {
    position: absolute;
    top: 50px;
    left: calc(@month-button-width / 2 - 1px);
    z-index: 0;
    display: block;
    // width: 2px;
    // background-color: @line-color;
    height: 100%;
    border-left: 2px solid @line-color;
    content: '';
  }

  &:last-child {
    &::after {
      position: absolute;
      bottom: -65px;
      left: calc(@month-button-width / 2 - 8px);
      width: 0;
      height: 0;
      border-color: @line-color transparent transparent transparent; /* 上下左颜色为透明，右边为红色 */
      border-style: solid;
      border-width: 8px; /* 控制三角形大小 */
      content: '';
    }
  }

  .month {
    position: relative;
    flex-shrink: 0;
    align-self: first baseline;
    width: @month-button-width;
    // padding: 0 12px;
    height: 32px;
    margin-top: -16px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 32px;
    text-align: center;
    background: @primary-color;
    border-radius: 50px;
  }

  .month-line {
    &::before {
      position: absolute;
      top: 16px;
      right: -50px;
      z-index: 1;
      display: block;
      width: 50px;
      // height: 2px;
      // background-color: @line-color;
      border-top: 2px solid @line-color;
      content: '';
    }
  }

  .container {
    position: relative;
    display: flex;
    flex-shrink: 0;
    gap: @axis-gap;
    padding-top: 30px;

    &::before {
      position: absolute;
      top: 0;
      left: -20px;
      z-index: 1;
      display: block;
      width: calc(100% - var(--lastElementWidth) / 2 + 20px);
      border-top: 2px solid @line-color;
      // height: 2px;
      // background-color: @line-color;
      content: '';
    }
  }

  .close {
    position: absolute;
    top: -8px;
    left: 30px;
    z-index: 9;
    display: flex;
    background-color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .axis-item {
    position: relative;
    flex-shrink: 0;
    width: fit-content;
    min-width: @axis-min-width;
    max-width: 534px;
    height: fit-content;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;

    &::before {
      position: absolute;
      top: -9px;
      left: 50%;
      display: block;
      width: 5px;
      height: 5px;
      background-color: #fff;
      border: 2px solid @line-color;
      border-radius: 50%;
      transform: translateX(-50%);
      content: '';
    }

    &::after {
      position: absolute;
      top: -29px;
      left: 50%;
      display: block;
      height: 20px;
      border-left: 2px solid @line-color;
      // width: 2px;
      // background-color: @line-color;
      transform: translateX(-50%);
      content: '';
    }

    .day {
      position: absolute;
      top: -58px;
      left: 50%;
      z-index: 10;
      width: @axis-day-width;
      text-align: center;
      transform: translateX(-50%);
    }

    .timeline {
      position: relative;

      &::after {
        position: absolute;
        top: -34px;
        left: 50%;
        z-index: 10;
        display: block;
        width: 6px;
        height: 6px;
        background-color: @blue-color;
        border: 1px solid #c4daff;
        border-radius: 50%;
        transform: translateX(-50%);
        content: '';
      }

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 5px 12px;
        color: #fff;
        font-size: 14px;
      }

      .axis {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 8px 12px;
      }

      .detail {
        position: relative;
        padding-left: 12px;
        color: #2c4060;
        font-size: 16px;
        word-wrap: break-word;
        word-break: break-all;
        line-height: 24px;

        &::before {
          position: absolute;
          top: 10px;
          left: 0;
          display: block;
          width: 6px;
          height: 6px;
          background-color: @blue-color;
          border-radius: 50%;
          content: '';
        }
      }

      .preview {
        padding-left: 12px;
      }
    }
  }

  .red {
    border-color: @red-color;

    .timeline {
      .header {
        background-color: @red-color;
      }

      .detail {
        &::before {
          background-color: @red-color;
        }
      }
    }
  }

  .blue {
    border-color: @blue-color;

    .timeline {
      .header {
        background-color: @blue-color;
      }

      .detail {
        &::before {
          background-color: @blue-color;
        }
      }
    }
  }

  .green {
    border-color: @green-color;

    .timeline {
      .header {
        background-color: @green-color;
      }

      .detail {
        &::before {
          background-color: @green-color;
        }
      }
    }
  }

  .purple {
    border-color: @purple-color;

    .timeline {
      .header {
        background-color: @purple-color;
      }

      .detail {
        &::before {
          background-color: @purple-color;
        }
      }
    }
  }
}
