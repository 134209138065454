.tabHeader {
  display: flex;
  justify-content: space-between;
  background: #F6F8FB;
  line-height: 36px;
  padding: 6px 16px;
  width: 100%;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 12px;
  .left {
    flex: 1;
  }
  .right {
    flex: 0 1 auto;
  }
}
