@import "@/style/var.less";
.dicomUpload {
  background: #F6F8FB;
  border: 1px solid #DCDFE6;
  border-radius: 4px;
  padding: 8px 12px;
  display: inline-block;
  color: #4288FF;
  font-weight: 500;
  cursor: default;
  &.readOnly {
    padding: 0;
    background: transparent;
    border: none;
  }
  .actionItem {
    cursor: pointer;
  }
}
.tag {
  margin-left: 12px;
  :global {
    .anticon-check-circle {
      color: @success-color;
    }
    .anticon-close-circle {
      color: @error-color;
    }
  }
}
