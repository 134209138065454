.flex {
  display: flex;
  gap: 12px;

  .left {
    flex: 1;
  }

  :global {
    #dynamic_form {
      flex: 1;
    }

    #ocrInfo {
      max-width: 358px;
    }
  }
}
