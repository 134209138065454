.header {
  display: flex;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #0000001c;
}

.header-container {
  display: flex;
  // justify-content: space-between;
  gap: 12px;
  align-items: center;
  min-height: 56px;
  margin-right: 36px;
  padding: 0 12px;
  background: #ffffff;
  border-radius: 4px;

  .title {
    position: relative;
    flex-shrink: 0;
    color: #303030;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    &::before {
      position: absolute;
      top: 50%;
      left: -12px;
      display: inline-block;
      width: 4px;
      height: 14px;
      background-color: #4288ff;
      border-radius: 0 2px 2px 0;
      transform: translateY(-50%);
      content: ' ';
    }
  }
}
