.patientInfo {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 16px;
  width: 100%;

  .content {
    width: 100%;
    padding: 0 16px;
    background: #F6F8FB;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .action {
    //float: right;
    margin-left: 16px;
  }

  :global {
    .but-title {
      margin-left: -16px;
      //display: flex;
      //align-items: center;
      //justify-content: space-between;
    }
  }
}
