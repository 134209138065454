.addForm {
  height: 560px;
  overflow: auto;
  padding: 12px 10px 0 0;
  :global {
    .ant-form-item {
      margin-bottom: 12px;
    }
  }
}
.footer {
  margin-top: 12px;
  padding-top: 12px;
  text-align: right;
}
