.richRender {
  width: 100%;
  overflow: auto;
  white-space: pre-wrap;
  word-break: break-all;

  :global {
    video {
      height: 240px;
      width: 100%;
    }
  }
}