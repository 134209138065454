.images {
  .item {
    width: 100px;
    height: 100px;
    background: #8bafe359;
    border: 1px solid #efefef;
    border-radius: 4px;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    :global {
      .ant-image {
        height: 100%;
        width: 100%;
        display: flex;
        img {
          margin: auto;
          max-height: 100px;
          max-width: 100px;
        }
      }
    }
  }
}
.imageModal {
  display: flex;
  .item {
    width: 500px;
    height: 500px;
    display: flex;
    padding: 0 6px;
    :global {
      .ant-image {
        margin: auto;
        max-width: 100%;
        max-height: 100%;
        img {
          width: 488px;
        }
      }
    }
  }
}
