.recordItemBox {
  margin-bottom: 24px;
  .header {
    display: flex;
    align-items: center;
    padding: 6px 16px;
    background: #f6f8fb;
    border-radius: 4px;

    .label {
      margin-right: 12px;
    }
    .action {
      float: right;
      line-height: 32px;
    }
    :global {
      .ant-badge {
        margin-top: 5px;
        margin-right: 12px;
      }
      .ant-form-item {
        display: inline-block;
        margin-bottom: 0;
      }
    }
  }
  .body {
    display: flex;
    gap: 24px;
    padding-top: 16px;
    .left {
      flex: 1;
    }
    .right {
      flex: 0 0 calc(50vw - 138px);
      max-height: 440px;
      padding: 16px;
      overflow: auto;
      border: 1px solid #dcdfe6;
      border-radius: 4px;
      .content {
        margin-top: 12px;
      }
    }
  }
}
