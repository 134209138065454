@import '@/style/mixin.less';

.speech-subfield {
  width: 620px;
  overflow-y: auto;
  flex-shrink: 0;
  padding: 0 16px 16px;
  background: #ffffff;
}

.subfield {
  height: calc(100vh - 126px);
  overflow-y: auto;
  .scroll-bar();
}