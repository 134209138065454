.speech-footer {
  padding: 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: #00000040;
  text-align: center;
  line-height: 24px;
  justify-content: center;

  .logo {
    width: 80px;
    height: 27px;
    background-image: url('~@/assets/images/lecture/logo.png');
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 12px;
    background-position: center center;
  }
}