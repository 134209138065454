@import "@/style/var.less";
.info {
  background-color: #fff;
  width: 100%;
  height: 100%;
  .header {
    padding: 16px 24px 16px 0;
    border-bottom: 1px solid #DFE4ED;
    .action {
      float: right;
    }
    :global {
      .but-title {
        margin-bottom: 0;
      }
    }
  }
  .body {
    padding: 32px 16px;
    .avatarBox {
      text-align: center;
      padding-bottom: 32px;
      .avatar{
        width: 80px;
        height: 80px;
        margin-bottom: 16px;
        border-radius: 50%;
        overflow: hidden;
      }
      .name {
        >div {
          &:first-child {
            font-weight: 500;
            font-size: 24px;
            color: #0B2145;
          }
          &:last-child {
            margin-top: 16px;
            font-size: 16px;
            color: #0b2145a6;
          }
        }
      }
    }
    .fields {
      &.baseInfoFields {
        border-radius: 4px;
        background: #F4F8FE;
        margin-bottom: 8px;
        .item {
          font-weight: 500;
          font-size: 16px;
          color: #0B2145;
          margin-bottom: 16px;
        }
      }
      padding: 24px 16px;
      .title {
        font-weight: 500;
        font-size: 16px;
        color: #0B2145;
        margin-bottom: 16px;
        >a {
          float: right;
        }
        :global {
          .iconfont {
            color: @primary-color;
            margin-right: 4px;
            font-size: 18px;
          }
        }
      }
      .item {
        font-size: 14px;
        color: #0B2145;
        margin-bottom: 8px;
        line-height: 24px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
