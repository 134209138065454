@import '@/style/mixin.less';

.speech-header {
  display: flex;
  align-items: center;
  height: 72px;
  background: #ecf2f9;
  background-image: url('~@/assets/images/lecture/logo_new.png');
  background-repeat: no-repeat;
  background-size: contain;
  box-shadow: 0 2px 8px 0 #0000001a;

  .speech-logo {
    flex-shrink: 0;
    width: 262.86px;
    height: 90px;
    margin: 0;
    padding: 0;
    color: rgba(255, 255, 255, 0);
    background-image: url('~@/assets/images/lecture/logo_new.png');
    background-repeat: no-repeat;
    background-size: contain;
  }

  .header-container {
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    // justify-content: space-between;
    justify-content: right;

    .header-title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-right: 40px;
      // flex: 1;
      // position: absolute;
      // left: 50%;
      // transform: translateX(-50%);

      .speech-title {
        margin: 0;
        margin-bottom: 5px;
        color: #000000;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
      }

      .speech-doctor {
        color: #303030;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
      }
    }

    .header-tools {
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: space-between;
      min-width: 502px;
      padding: 0 30px;
      // position: absolute;
      // right: 0;

      .font-size {
        // margin: 0 16px;
        display: flex;
        align-items: center;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(221, 223, 230, 1);
        border-radius: 18px;
      }

      .font-label {
        padding: 0 16px;

        color: #303030;
        font-size: 14px;
      }

      .font-select {
        width: 90px;

        :global(.ant-select-selector) {
          color: #303030;
          background: rgba(255, 255, 255, 1);
          border-color: rgba(221, 223, 230, 1);
          border-top: 0;
          border-right: 0;
          border-bottom: 0;
          border-radius: 0 30px 30px 0;
        }
      }

      .hover-effect {
        color: rgba(66, 136, 255, 1);
        cursor: pointer;
      }
    }

    .tools-width {
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: 1669px) {
  .header-container {
    justify-content: space-between;

    .header-title {
      position: absolute;
      left: 50%;
      flex: 1;
      padding-right: 0;
      transform: translateX(-50%);
    }

    .header-tools {
      position: absolute;
      right: 0;
    }
  }
}
