.userInfo {
  background: #F6F6F6;
  border-radius: 18px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  padding-right: 12px;
  position: relative;
  padding-left: 50px;
  .avtar {
    position: absolute;
    left: 0;
    top: 0;
    border: 2px solid #fff;
    box-sizing: border-box;
    width: 38px;
    height: 38px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    text-align: center;
    :global {
      .ant-image {
        width: 100%;
        height: 100%;
        padding: 0;
        img {
          width: 100%;
          height: 100%;
          vertical-align: top;
        }
      }
    }
    :global(.anticon) {
      font-size: 20px;
      color: #ccc;
    }
  }
  span {
    cursor: pointer;
  }
}
