.body {
  .desc {
    margin-bottom: 12px;
  }

  .images {
    .item {
      display: flex;
      width: 600px;
      height: 340px;
      background: #8bafe359;
      border-radius: 4px;

      :global {
        .ant-image-img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
