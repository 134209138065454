@import "@/style/var.less";

.item {
  display: inline-block;
  width: 436px;
  background: #F6F8FB;
  padding: 24px;
  position: relative;
  border-radius: 4px;

  .removeBtn {
    position: absolute;
    right: 18px;
    top: 18px;
    cursor: pointer;
    color: @error-color;
    z-index: 999;
  }
}