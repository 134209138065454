.imageEditor {
  width: 100%;

  // height: 622px;
  :global {
    //.tui-image-editor-range-wrap {
    //  display: none!important;
    //}
    .tie-image-editor {
      width: 100%;
      // height: 620px;
      border: 1px solid #f5f5f5;
      border-radius: 6px;
    }

    .tie-image-editor_tool-upload {
      display: none;
    }

    .tie-image-editor_canvas {
      border: 1px solid #f5f5f5;
    }

    .tie-image-editor_modal__wrapper {
      display: flex;

      canvas {
        margin: auto;
      }
    }
  }
}
