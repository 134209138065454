.label {
  padding: 2px 8px;
  line-height: 19px;
  background: #E6F1FC;
  border: 1px solid #A3D0FD;
  border-radius: 4px;
  color: #4288FF;
  font-weight: 500;
  font-size: 14px;
  flex-shrink: 0;
}