.associated-details {
  position: relative;

  .associated {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    min-width: 968px;
    overflow: hidden;
    background-color: #fff;
    border-radius: 4px;
    // border: 1px solid rgba(5, 5, 5, 0.13);
    box-shadow: 0 0 6px 0 #0000001f;
    transform: translateY(calc(100% + 4px));
  }
}

.action-footer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
}
