@import '@/style/var.less';

.upload {
  .record-img {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    align-items: center;
  }
}

.sortable-chosen {
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: -4px;
    display: block;
    width: 2px;
    height: 100%;
    background-color: @error-color;
    transform: translateY(-50%);
    content: '';
  }
}
