.layout {
  height: 100vh;

  .sider {
    z-index: 999;
    background: #ecf2f9;

    .logo {
      margin-bottom: 8px;
      padding-top: 12px;
      text-align: center;

      > div {
        display: inline-block;

        img {
          height: 20px;
          vertical-align: text-bottom;
          transition: height 0.3s;
        }

        .name {
          display: inline-block;
          margin-left: 8px;
          color: #303030;
          font-weight: 500;
          font-size: 20px;
        }
      }
    }

    :global {
      .ant-menu {
        max-height: calc(100vh - 80px);
        overflow: auto;
        background-color: #ecf2f9;
        border-right: none;

        .ant-menu-item {
          margin-left: 0;
          border-radius: 0 100px 100px 0;

          &-selected {
            position: relative;
            color: #fff;
            background-image: linear-gradient(227deg, #83ddf8 0%, #4c83ee 90%);

            &:after {
              position: absolute;
              top: 0;
              left: 0;
              width: 4px;
              height: 100px;
              background: #0857de;
            }
          }
        }

        .ant-menu-submenu-title {
          margin-left: 0;
        }

        .ant-menu-sub.ant-menu-inline {
          background: none;
        }
      }
    }
  }

  .header {
    z-index: 99;
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 0 20px 0 8px;
    background-color: #fff;
    border-bottom: 1px solid #f0f0f0;
    box-shadow: 0 -2px 16px 0px #bebbbb9e;

    .pageInfo {
      display: flex;
      flex: 1;
      align-items: center;

      .collapsedBtn {
        width: 54px;
        height: 54px;
        vertical-align: middle;
      }
    }

    .toolbar {
      flex: 2;
      text-align: right;

      .fullBtn {
        :global {
          .anticon {
            color: rgba(11, 33, 69, 0.85);
            border: 1px solid rgba(11, 33, 69, 0.85);
            border-radius: 2px;
          }
        }
      }
    }
  }

  .content {
    box-sizing: border-box;
    height: calc(100vh - 68px);
    padding: 12px !important;
    overflow-x: hidden;
    overflow-y: auto;
    //background-color: #fff;

    &::-webkit-scrollbar-track-piece {
      background: #d3dce6;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #99a9bf;
      border-radius: 20px;
    }
  }
}
