.imageModal {
  display: flex;

  .item {
    width: 500px;
    height: 500px;
    display: flex;
    padding: 0 6px;

    :global {
      .ant-image {
        margin: auto;
        max-width: 100%;
        max-height: 100%;

        img {
          width: 488px;
        }
      }
    }
  }
}