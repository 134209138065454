@import '@/style/mixin.less';

.video-model {

  :global(.ant-modal) {
    // display: flex;
    // flex-direction: column;
    // margin: 0 !important;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    // max-height: calc(100vh - 30px);
    // max-width: calc(100vw - 30px);
    top: 10px;

    .ant-modal-content {
      flex: 1;
      overflow: auto;
      .scroll-bar();
    }
  }
}


.speech-display {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .video {
    height: calc(100vh - 126px);
  }
}