.step-card {
  background: #f6f8fb;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  margin-left: 14px;

  &:last-child {
    margin-bottom: 0;
  }
}