@import '@/style/mixin.less';

.speech-subfield {
  flex-shrink: 0;
  width: 620px;
  overflow-y: auto;
  background: #ffffff;
}

.subfield {
  overflow-y: auto;
  .scroll-bar();
}
