.team {
  display: inline-block;
  border-radius: 18px;
  background: #F6F6F6;
  height: 36px;
  line-height: 36px;
  .left {
    display: inline-block;
    color: rgba(11, 33, 69, 0.65);
    padding: 0 16px;
    border-right: 1px solid rgba(11, 33, 69, 0.1);;
  }
  .right {
    display: inline-block;
    padding: 0 16px 0 0;
  }
}
