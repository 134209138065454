.frequency-top {
  padding-right: 24px;

  .title {
    font-weight: 600;
    font-size: 16px;
    color: #0163A3;
    padding: 16px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 14px;
      background: #4288FF;
      border-radius: 0 4px 4px 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .top-container {

    .top {
      display: flex;
      align-items: center;
      padding: 6px 12px;
      justify-content: space-between;

      .rank {
        width: 22px;
        height: 22px;
        background: rgba(255, 255, 255, 0.5);
        border-radius: 50%;
        display: flex;
        font-weight: 500;
        font-size: 14px;
        flex-shrink: 0;
        color: #6C808D;
        align-items: center;
        justify-content: center;
        margin-right: 45px;
      }

      .rank-1 {
        color: #FFF;
        background: #ECBB28;
      }

      .rank-2 {
        color: #FFF;
        background: #76DDFB;
      }

      .rank-3 {
        color: #FFF;
        background: #4288FF;
      }

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #6C808D;
        flex: 1;
      }

      .count {
        width: 60px;
        text-align: right;
        font-weight: 500;
        font-size: 14px;
        color: #6C808D;
      }
    }
  }
}