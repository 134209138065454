.upload {
  width: 364px;
  height: 268px;
  background: #ffffff05;
  border: 1px #00000033 dashed;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  .uploadContent {
    margin: auto;
    text-align: center;
    color: #7B8088;
    :global {
      img {
        max-width: 364px;
        max-height: 268px;
      }
      .anticon {
        font-size: 32px;
      }
    }
  }
}
