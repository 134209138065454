@import '@/style/var.less';
.img {
  position: relative;
  display: flex;
  width: 100px;
  height: 100px;
  background-color: #8bafe359;
  border: 1px solid #efefef;
  border-radius: 4px;
  &.ocrIng {
    background-color: #e0e0e0;
  }
  .ocrRes {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 4px 0;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: @green-color;
    opacity: 0.8;
  }
  .previewBox {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .actions {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      text-align: center;
      transform: translateY(-50%);
    }
    .ocrBtn {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 4px 0;
      font-size: 12px;
      text-align: center;
      background: #4288ff;
      &:hover {
        background-color: #4c83ee;
      }
    }
  }
  &:hover {
    .videoAction {
      display: flex;
    }
  }
  .videoAction {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    display: none;
    width: 100%;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.15);
    .btn {
      position: relative;
      z-index: 100;
      margin: auto;
      color: #fff;
    }
  }

  :global {
    .ant-image {
      display: flex;
      width: 100%;
      height: 100%;
      overflow: hidden;
      border-radius: 4px;
      img {
        max-width: 100px;
        max-height: 100px;
        margin: auto;
      }
    }
    video {
      width: 100px;
      height: 100px;
      margin: auto;
    }
  }
}
