.tool-card {
  background-image: url('~@/assets/images/statistic/activity_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;
  height: 500px;

  .card-header {
    display: flex;
    padding: 36px 0 8px 32px;

    .card {
      display: flex;
      align-items: center;

      .selected {
        margin-right: 40px;

        :global(.ant-select-selector) {
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid rgba(255, 255, 255, 0.75);
          border-radius: 4px;
        }
      }

      .picker {
        width: 270px;
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid rgba(255, 255, 255, 0.75);
        border-radius: 4px;
      }

      .card-label {
        font-weight: 500;
        font-size: 14px;
        color: #0163A3;
      }
    }
  }

  .card-footer {
    padding-left: 32px;
  }
}