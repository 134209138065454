.title {
  font-weight: 600;
  font-size: 16px;
  color: #0163A3;
  padding: 16px;
  position: relative;

  &::after {
    content: "";
    display: block;
    width: 4px;
    height: 14px;
    background: #4288FF;
    border-radius: 0 4px 4px 0;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.incoming-case {
  padding-right: 30px;
  padding-top: 8px;

  .case {
    background: #EEF0F8;
    box-shadow: inset -2px -2px 16px 1px #ffffffbf, inset 1px 1px 8px 1px #9198aa59;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 24px 20px 20px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    .header {
      flex: 1;

      .label {
        font-size: 16px;
        color: #0b2145a6;
        padding-left: 12px;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          background: #0857debf;
          border-radius: 2px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .count {
        font-weight: 500;
        font-size: 32px;
        color: #0857debf;
        line-height: 47px;
      }
    }

    .footer {
      flex: 1;

      .label {
        font-size: 16px;
        color: #6C808D;
        padding-left: 12px;
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          background: #52B5FF;
          border-radius: 2px;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }
      }

      .count {
        font-weight: 500;
        font-size: 28px;
        color: #52B5FF;
        line-height: 47px;
      }
    }
  }
}