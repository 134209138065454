.compare-title {
  position: relative;
  padding: 16px;
  color: #303030;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;

  &::before {
    position: absolute;
    top: 50%;
    left: 0px;
    display: inline-block;
    width: 4px;
    height: 14px;
    background-color: #4288ff;
    border-radius: 0 2px 2px 0;
    transform: translateY(-50%);
    content: ' ';
  }
}

.detail-container {
  display: flex;
  border: 1px solid #e6e6e6;
  border-radius: 4px;

  .detail-before {
    flex: 1;
    height: 400px;
  }

  .detail-title {
    padding: 16px;
    color: #0b2145;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    background: #f5f5f5;
  }

  .container {
    height: 400px;
    padding: 16px;
    overflow-y: auto;
  }

  .fields-name {
    margin-bottom: 8px;
    margin-left: -7px;
  }

  .field-item {
    margin-bottom: 18px;
    color: #0b2145;
    font-size: 14px;
    line-height: 22px;
  }

  .detail-after {
    flex: 1;
    border-left: 1px solid #e6e6e6;
  }
}
