.upload {
  padding: 0;
  margin: 0;
  width: 100px;
  height: 100px;
  .content {
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    :global {
      .ant-image {
        width: 100%;
        height: 100%;
      }
    }
    .img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
