.timeLine {
  .body {
    padding: 24px 0px 24px 8px;
    .timeItem {
      cursor: pointer;
      &.selected {
        color: #4288FF;
      }
    }
    .deleteBtn {
      color: #4288FF;
      margin-left: 12px;
      cursor: pointer;
    }
    :global {
      .ant-timeline-item {
        //padding-bottom: 10px;
        .ant-timeline-item-content {
          margin-left: 18px;
        }
      }
    }
  }
  :global {
    .ant-btn-primary {
      color: #4288FF;
      border-radius: 4px;
      background: #F6F8FB;
      &:disabled {
        border: none;
        color: rgba(0, 0, 0, 0.25);
      }
    }
  }
}
