.tab {
  :global(.ant-tabs-nav-wrap) {
    padding-left: 16px;
  }

  :global(.ant-tabs-content) {
    padding: 0 16px;
  }

  :global(.ant-table-cell-scrollbar) {
    display: none;
  }
}
