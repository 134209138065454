.field-canvas {
  background-image: url('~@/assets/images/statistic/bg_icon.png');
  background-position: 0 0;
  flex: 1;
  margin-right: 16px;
  height: 100%;

  &:last-child {
    margin-right: 0;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    color: #0163A3;
    padding: 16px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #3779AE;
      box-shadow: 0 6px 20px 0 #015596cc;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 14px;
      background: #4288FF;
      border-radius: 0 4px 4px 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

  }

  .count {
    font-weight: 500;
    font-size: 32px;
    color: #0163A3;
    letter-spacing: 0;
    line-height: 40px;
    padding-left: 32px;
    padding-bottom: 16px;
  }

  .field {
    display: flex;
    padding: 0 16px;
    padding-bottom: 20px;
    position: relative;

    .field-item {
      display: flex;
      padding-left: 14px;
      flex: 1;
      word-wrap: normal;
      position: relative;

      &::before {
        content: "";
        width: 8px;
        height: 8px;
        background: #0857debf;
        border-radius: 2px;
        position: absolute;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }

      .field-label {
        font-size: 16px;
        color: #6C808D;
        flex-shrink: 0;
        max-width: 120px;
      }

      .field-value {
        font-weight: 500;
        font-size: 16px;
        color: #0857debf;
        line-height: 24px;
        word-wrap: normal;
      }

      &:last-child {
        &::before {
          background: #52B5FF;
        }

        .field-value {
          color: #52B5FF;
        }
      }
    }

    &::after {
      content: "";
      display: block;
      width: calc(100% - 32px);
      height: 0.6px;
      background-color: #4288ff59;
      position: absolute;
      left: 16px;
      bottom: 0;
    }
  }

  .field-footer {
    font-size: 16px;
    color: #6C808D;
    padding: 20px 32px;
    position: relative;

    &::before {
      content: "";
      width: 8px;
      height: 8px;
      background: #b3b3b3bf;
      border-radius: 2px;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
