@import "@/style/var.less";
.formBox {
  padding-top: 12px;
  .step {
    background: #F5F7FA;
    border-radius: 4px;
    :global {
      .ant-steps-item-container {
        padding-bottom: 6px;
      }
      .ant-steps-item-process .ant-steps-item-icon {
        background-color: #FFFFFF;
        .ant-steps-icon {
          color: #4288ff;
        }
      }
    }
  }
  .body {
    padding-top: 32px;
    .step1Box {
      width: 492px;
      margin: 0px auto;
    }
    .step2Box {
      .deleteBtn {
        color: @error-color;
        cursor: pointer;
      }
      .tableTitle {
        padding: 0px;
      }
      :global{
        .ant-form-item {
          margin-bottom: 0;
        }
        .ant-table-thead {
          .ant-table-cell {
            padding: 13px 15px;
          }
        }
        .ant-table-cell, .ant-table-footer {
          background-color: #fff;
        }
      }
    }
  }
  .footer {
    padding-top: 24px;
    text-align: right;
  }
}
