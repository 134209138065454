@import '@/style/var.less';

.speech-file {
  position: relative;

}

.close-icon {
  background-color: #fff;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 999;
  color: @error-color;
}