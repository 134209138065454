@import '@/style/mixin.less';

.speech-patient {
  padding: 0 16px 16px;

  .container {
    background: #f6f8fb;
    border-radius: 4px;
    padding: 16px;
  }

  .patient {
    display: flex;
    flex-wrap: wrap;
  }

  .speech-form-item {
    margin-bottom: 0;
  }

  .patient-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    flex-shrink: 0;
    width: 300px;
    box-sizing: border-box;
    padding-right: 16px;

    .item-label {
      background: #e6f1fc;
      border: 1px solid #a3d0fd;
      border-radius: 4px;
      font-weight: 500;
      flex-shrink: 0;
      font-size: 14px;
      .addFontSize(14px);
      color: #4288FF;
      letter-spacing: 0;
      line-height: 16px;
      padding: 4px 8px;
      margin-right: 10px;
    }

    .item-name {
      font-size: 14px;
      .addFontSize(14px);
      color: #000000bf;
      letter-spacing: 0;
      line-height: 24px;
      word-break: break-all;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }

  .footer {
    display: flex;
    // align-items: center;
    padding-top: 16px;
    position: relative;

    &::before {
      content: '';
      display: block;
      width: 100%;
      // height: 1px;
      // background-color: #97979759;
      border-top: 1px dashed #97979759;
      position: absolute;
      top: 0;
      left: 0;
    }

    .footer-label {
      background: #4288FF;
      border: 1px solid #a3d0fd;
      border-radius: 4px;
      flex-shrink: 0;
      font-weight: 500;
      align-self: flex-start;
      font-size: 14px;
      .addFontSize(14px);
      color: #ffffff;
      letter-spacing: 0;
      line-height: 16px;
      padding: 4px 8px;
    }

    .footer-desc {
      font-size: 14px;
      .addFontSize(14px);
      color: #000000;
      line-height: 24px;
      padding-left: 10px;
      word-break: break-all;
      // overflow: hidden;
      // display: -webkit-box;
      // -webkit-line-clamp: 1;
      // -webkit-box-orient: vertical;
    }
  }

  .form-footer {
    align-items: flex-start;

    .footer-label {
      margin-right: 10px;
    }
  }
}