.axis-header {
  position: absolute;
  top: -12px;
  left: -12px;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% + 24px);
  padding-right: 16px;
  background-color: #fff;

  .header-container {
    display: flex;
    gap: 20px;
    align-items: center;

    .axis-title {
      position: relative;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      padding-left: 16px;
      color: #303030;
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      cursor: pointer;

      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        width: 4px;
        height: 14px;
        background: #4288ff;
        border-radius: 0 4px 4px 0;
        transform: translateY(-50%);
        opacity: 1;
        content: '';
      }
    }
  }
}

.axis-lecture {
  top: -43px;
  right: 0;
  left: 130px;
  width: auto;
}
