.patient-tag {
  position: relative;
  padding: 5px 16px;
}

.tag-round {
  position: relative;
  border-radius: 20px;
}

.tag-default {
  color: #b7bec9;
  background: #f6f8fb;
}

// :global(.patient-tag .ant-tag-warning) {
//   color: #faad14;
//   background: #fffbe6 !important;
//   border-color: #ffe58f !important;
// }

.tag-border-none {
  border: 0;
}
