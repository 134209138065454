@import '@/style/mixin.less';

.team-mdt-rank {
  background-image: url('~@/assets/images/statistic/bg_icon.png');
  background-position: 0 0;
  height: 100%;

  .title {
    font-weight: 600;
    font-size: 16px;
    color: #0163A3;
    padding: 16px;
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background: #3779AE;
      box-shadow: 0 6px 20px 0 #015596cc;
      position: absolute;
      top: 0;
      left: 0;
    }

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 14px;
      background: #4288FF;
      border-radius: 0 4px 4px 0;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

  }

  .mdt-rank {
    padding: 16px 24px;
    height: calc(500px - 130px);
    overflow-y: auto;
    .scroll-bar(2px);

    .rank {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;

      .name {
        font-weight: 500;
        font-size: 14px;
        color: #6C808D;
        margin-right: 14px;
        line-height: 20px;
        max-width: 120px;
        word-break: break-all;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .tip-name {
        font-size: 14px;
        color: #FFFFFF;
      }

      .tip-label {
        font-size: 12px;
        color: #FFFFFF;
      }

      .progress {
        height: 4px;
        flex: 1;
        background: #C7D5E8;
        border-radius: 1px;
        position: relative;
      }

      .progress-discussion {
        // width: 90%;
        max-width: 100%;
        height: 100%;
        transition: all 0.5s;
        background-image: linear-gradient(270deg, #0065A8 0%, #C7D5E8 100%);
        position: relative;
        z-index: 9;
      }

      .loop {
        width: 8px;
        height: 8px;
        padding: 4px;
        border-radius: 50%;
        cursor: pointer;
        background: transparent;
        border: 1px solid rgba(128, 200, 255, 0.85);
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);

        &::after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          background: #0065A8;
          border-radius: 50%;
          box-shadow: 0 1px 4px 0 rgba(0, 101, 168, 0.6);
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
        }
      }

      .progress-share {
        height: 100%;
        max-width: 100%;
        transition: all 0.5s;
        background-image: linear-gradient(270deg, #92CC73 0%, #C7D5E8 100%);
        position: relative;
        z-index: 9;
      }

      .share-loop {
        width: 8px;
        height: 8px;
        padding: 4px;
        flex-shrink: 0;
        border-radius: 50%;
        cursor: pointer;
        background: transparent;
        border: 1px solid #AAE18D;
        position: absolute;
        right: -10px;
        top: 50%;
        transform: translateY(-50%);

        &::after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          background: rgba(146, 204, 115, 1);
          border-radius: 50%;
          box-shadow: 0 1px 4px 0 #AAE18D;
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
        }
      }
    }
  }

  .rank-color {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 14px;

    .color {
      font-size: 14px;
      color: rgba(11, 33, 69, 0.65);
      letter-spacing: 0;
      line-height: 16px;
      padding-left: 30px;
      cursor: pointer;
      position: relative;

      &:first-child {
        margin-right: 32px;
      }

      &::before {
        content: "";
        display: block;
        width: 20px;
        height: 14px;
        opacity: 0.75;
        background: #0065A8;
        border-radius: 3px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child {
        &::before {
          background: rgba(146, 204, 115, 1);
        }
      }
    }
  }
}