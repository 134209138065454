.time-axis {
  flex: 1;
  flex-shrink: 0;
}

.horizontal-flex {
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
}
