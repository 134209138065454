.container {
  background-image: url('~@/assets/images/statistic/map_icon.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-size: 100% 100%;

  .chart-title {
    font-weight: 600;
    font-size: 16px;
    color: #0163A3;
    padding: 32px 48px 30px 48px;
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 14px;
      background: #4288FF;
      border-radius: 0 4px 4px 0;
      position: absolute;
      left: 32px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .city {
    padding: 0 80px;
    display: flex;
    align-items: center;
    gap: 24px;
    justify-content: space-between;

    .city-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &:last-child {
        padding-right: 0;
      }
    }

    .name {
      font-size: 16px;
      color: rgba(11, 33, 69, 0.65);
      padding-left: 12px;
      position: relative;

      &::before {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        background: rgba(8, 87, 222, 0.75);
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .count {
      font-weight: 500;
      font-size: 32px;
      height: 47px;
      color: #0163A3;
    }
  }
}
