.navbar {
  display: flex;
  align-items: center;

  .pageTitle {
    flex-shrink: 0;
    padding-left: 8px;
    color: #303030;
    font-weight: 500;
    font-size: 20px;
    vertical-align: middle;
  }
}
