@import '@/style/mixin.less';

.speech-main {
  flex: 1;
  height: calc(100vh - 82px);
  overflow-y: auto;
  padding: 10px;
  .scroll-bar();
  transition: all 1s;
  scroll-padding-top: 16px;
}