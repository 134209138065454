@import '@/style/mixin.less';

.speech-issue {
  flex-shrink: 0;
  padding: 16px;
  overflow: hidden;
  border-radius: 4px;

  .issue-container {
    padding: 0 16px;
    background: #f6f8fb;
    border-radius: 4px;

    .issue-border {
      border-bottom: 1px dashed #97979759;

      &:last-child {
        border-bottom: 0;
      }
    }

    .issue-item {
      display: flex;
      align-items: center;
      padding: 16px;
      background: #f6f8fb;

      .item-header {
        display: flex;
        align-items: center;

        .header-icon {
          display: flex;
          flex-shrink: 0;
          align-items: center;
          box-sizing: border-box;
          height: 26px;
          padding: 4px 8px;
          color: #ffffff;
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0;
          background: #4288ff;
          border: 1px solid #a3d0fd;
          border-radius: 4px;
          .addFontSize(14px);
        }
      }

      .container {
        color: rgba(0, 0, 0, 0.75);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0;
        text-align: justify;
        word-break: break-all;
        .addFontSize(14px);
      }
    }

    .issue-chart {
      display: flex;
      padding: 0 16px 16px;
    }
  }
}
