@import '@/style/mixin.less';

.mdt-statistics {
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  background-color: rgba(150, 189, 239, 1);
  padding-top: 132px;
  .scroll-bar(2px);
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100px;
    background-image: url('~@/assets/images/statistic/header.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100px;
    position: absolute;
    left: 0;
    top: 0;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 13px;
    width: 300px;
    height: 30px;
    font-family: YouSheBiaoTiHei;
    background-image: url('~@/assets/images/statistic/title_icon.png');
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 300px 30px;
    font-size: 32px;
    line-height: 47px;
    color: #0163A3;
    letter-spacing: 1px;
    text-align: center;
    text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.9);
    transform: translateX(-50%);
  }

  .statistics-tab {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-family: YouSheBiaoTiHei;
    top: 72px;

    .tab {
      font-size: 20px;
      color: #0163A3;
      letter-spacing: 2px;
      font-weight: 550;
      text-align: center;
      line-height: 40px;
      margin-right: 100px;
      cursor: pointer;
      transition: all 0.5s;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &::after {
        content: "";
        display: block;
        width: 0;
        height: 4px;
        background: #8BF6FF;
        position: absolute;
        transition: all 0.5s;
        left: 0;
        bottom: -4px;
      }
    }

    .tab-active {
      text-shadow: 2px 1px 0 #8BF6FF;

      &::after {
        width: 100%;
      }
    }
  }

  .update-time {
    position: absolute;
    top: 101px;
    right: 16px;
    font-size: 14px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 16px;
  }

  .statistics-header {
    // display: flex;
    // justify-content: space-between;
    padding: 0 16px;
    margin-bottom: 16px;

    .header-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

  }

  .statistics-footer {
    // height: 23.8%;
    // display: flex;
    padding: 0 16px 16px 16px;
  }
}